import React, { useState } from 'react';
import componentStyles from './competencyTable.module.scss';
import { FaInfoCircle } from 'react-icons/fa';

const CompetencyHeader = ({ id, header, definition, children }) => {
	const [accordianOpen, toggleAccoridan] = useState(false);

	return (
		<>
			<b>{header}</b>
			<button
				className={componentStyles.defButton}
				aria-expanded={accordianOpen}
				onClick={() => toggleAccoridan(!accordianOpen)}
			>
				<FaInfoCircle />
			</button>

			<div
				id={id}
				role='region'
				aria-labelledby={id}
				hidden={!accordianOpen}
				className={componentStyles.definition}
			>
				{children}
			</div>
		</>
	);
};

export default CompetencyHeader;
