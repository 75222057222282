import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { useMediaQuery } from 'react-responsive';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { useIntl, FormattedMessage } from 'gatsby-plugin-intl';

import Layout from '../../components/Layouts/layout';
import SEO from '../../components/seo';
import CompetencyTable from '../../components/CompetencyTable/CompetencyTable';

const CompetencyRequirementsPage = () => {
	const intl = useIntl();
	const isScreenSm = useMediaQuery({ maxWidth: 765 });
	const data = useStaticQuery(graphql`
		query {
			builderImg: file(relativePath: { eq: "mintoBuilder03.jpg" }) {
				childImageSharp {
					fluid {
						...GatsbyImageSharpFluid
					}
				}
			}
		}
	`);
	return (
		<Layout
			view='builder'
			subheader={<FormattedMessage id='nav.builderDashB' />}
			header={
				<span className='bold'>
					<FormattedMessage id='becomeBuilder2.headerA' />
				</span>
			}
		>
			<SEO lang={intl.locale} title={intl.formatMessage({ id: 'becomeBuilder2.headerA' })} />
			<Container>
				<Row>
					<Col>
						<p>
							<FormattedMessage id='competencyRequirements.contentAi' />{' '}
							<a
								href={intl.formatMessage({
									id: 'becomeBuilder2.linkA'
								})}
								aria-label={intl.formatMessage({
									id: 'becomeBuilder2.contentAii'
								})}
							>
								<FormattedMessage id='becomeBuilder2.contentAii' />
							</a>
							.
						</p>
						<p>
							<FormattedMessage id='competencyRequirements.contentAii' />
						</p>
						<ol>
							<li>
								<FormattedMessage id='becomeBuilder2.list01A' />
							</li>
							<li>
								<FormattedMessage id='becomeBuilder2.list01B' />
							</li>
							<li>
								<FormattedMessage id='becomeBuilder2.list01C' />
							</li>
							<li>
								<FormattedMessage id='becomeBuilder2.list01D' />
							</li>
							<li>
								<FormattedMessage id='becomeBuilder2.list01E' />
							</li>
							<li>
								<FormattedMessage id='becomeBuilder2.list01F' /> *
							</li>
							<li>
								<FormattedMessage id='becomeBuilder2.list01G' /> *
							</li>
						</ol>
						<p className='footnote'>
							<FormattedMessage id='becomeBuilder2.contentAiv' />{' '}
							<span style={{ textDecoration: `underline` }}>
								<FormattedMessage id='becomeBuilder2.contentAv' />
							</span>{' '}
							<FormattedMessage id='becomeBuilder2.contentAvi' />
						</p>
						<p>
							<FormattedMessage id='competencyRequirements.contentAiii' />
						</p>
					</Col>
				</Row>

				<Row className='pageRow'>
					<Col>
						<h2>
							<FormattedMessage id='competencyRequirements.headerA' />
						</h2>
						<p>
							<FormattedMessage id='competencyRequirements.contentBi' />
						</p>
						<p>
							<FormattedMessage id='competencyRequirements.contentBii' />
						</p>
						<ol>
							<li>
								<FormattedMessage id='competencyRequirements.list01a' />
							</li>

							<li>
								<FormattedMessage id='competencyRequirements.list01bi' />{' '}
								<a href='https://corporate.algonquincollege.com/group/home-construction-regulatory-authority-courses-exams/'>
									<FormattedMessage id='competencyRequirements.list01bii' />
								</a>{' '}
								<FormattedMessage id='competencyRequirements.list01biii' />{' '}
								<a href='https://learn.phbi.ca/on/s/category/courses/exam-challenge/0ZGAf00000001irOAA?c__results_layout_state=%7B%7D'>
									<FormattedMessage id='competencyRequirements.list01biv' />
								</a>
								<FormattedMessage id='competencyRequirements.list01bv' />{' '}
								<a href=' https://www.trainingbuilders.ca/'>buildABILITY</a>
							</li>

							<li>
								<FormattedMessage id='competencyRequirements.list01c' />
							</li>
						</ol>

						<h3>
							<FormattedMessage id='competencyRequirements.headerB' />
						</h3>
						<p>
							<FormattedMessage id='competencyRequirements.contentCi' />{' '}
							<b>
								<FormattedMessage id='competencyRequirements.contentCii' />{' '}
								<FormattedMessage id='competencyRequirements.contentCvii' />
							</b>{' '}
							<FormattedMessage id='competencyRequirements.contentCiii' />{' '}
							<b>
								<FormattedMessage id='competencyRequirements.contentCiv' />
							</b>{' '}
							<FormattedMessage id='competencyRequirements.contentCv' />
						</p>

						<p>
							<FormattedMessage id='competencyRequirements.contentCvi' />
						</p>

						<CompetencyTable />
					</Col>
				</Row>

				<Row className='pageRow'>
					<Col lg={12} md={12}>
						<h2>
							<FormattedMessage id='competencyRequirements.headerC' />
						</h2>
						<p>
							<FormattedMessage id='competencyRequirements.contentDi' />
						</p>
						<p>
							<FormattedMessage id='competencyRequirements.contentDii' />
						</p>
					</Col>
					<Col lg={4} md={12}>
						<Img fluid={data.builderImg.childImageSharp.fluid} alt='' />
					</Col>
					<Col>
						<h3>
							<FormattedMessage id='competencyRequirements.headerD' />
						</h3>
						<p>
							<FormattedMessage id='competencyRequirements.contentEi' />
						</p>
						<p>
							<FormattedMessage id='competencyRequirements.contentEii' />
						</p>
						<ul>
							<li>
								<FormattedMessage id='competencyRequirements.list02a' />
							</li>
							<li>
								<FormattedMessage id='competencyRequirements.list02b' />
							</li>
							<li>
								<FormattedMessage id='competencyRequirements.list02c' />
							</li>
							<li>
								<FormattedMessage id='competencyRequirements.list02d' />
							</li>
							<li>
								<FormattedMessage id='competencyRequirements.list02e' />
							</li>
						</ul>

						<p>
							<FormattedMessage id='competencyRequirements.contentEiii' />
						</p>
						<ul>
							<li>
								<FormattedMessage id='competencyRequirements.list03a' />
							</li>
							<li>
								<FormattedMessage id='competencyRequirements.list03b' />
							</li>
							<li>
								<FormattedMessage id='competencyRequirements.list03c' />
							</li>
							<li>
								<FormattedMessage id='competencyRequirements.list03d' />
							</li>
							<li>
								<FormattedMessage id='competencyRequirements.list03e' />
							</li>
						</ul>
					</Col>
				</Row>

				<Row className='pageRow'>
					<Col>
						<h2>
							<FormattedMessage id='becomeBuilder2.headerG' />
						</h2>
						<p>
							<FormattedMessage id='competencyRequirements.contentFi' />{' '}
							<a
								href='../../../HCRA_CCEP_PreQualification_2023_11_15_v08.pdf'
								aria-label={intl.formatMessage({
									id: 'becomeBuilder2.contentGii'
								})}
							>
								<FormattedMessage id='becomeBuilder2.contentGii' />
							</a>
							<FormattedMessage id='competencyRequirements.contentFii' />
						</p>

						<ol className='largeNums'>
							<li>
								<span>
									<b>
										<FormattedMessage id='becomeBuilder2.list05A_B' />
									</b>
									<br />
									<a href='mailto:competency_info@hcraontario.ca'>competency_info@hcraontario.ca</a>
								</span>
							</li>

							<li>
								<span>
									<b>
										<FormattedMessage id='becomeBuilder2.list05B_B' />
									</b>
									<br />
									<FormattedMessage id='becomeBuilder2.list05Bi' />
									<br />
									<FormattedMessage id='becomeBuilder2.list05Bii' />
									<br />
									<FormattedMessage id='footer.addressA' />
									<br />
									<FormattedMessage id='footer.addressB' />
								</span>
							</li>
						</ol>

						<p>
							<FormattedMessage id='competencyRequirements.contentFiii' />
						</p>
					</Col>
				</Row>
			</Container>
		</Layout>
	);
};

export default CompetencyRequirementsPage;
